<template>
    <PublicLayout id="error-404">
        <Logo class="align-items-center my-2 my-md-5 my-lg-7"/>
        <h1 class="text-center display-1 m-0">404</h1>
        <p class="text-center mt-7 display-4 text-secondary">Sorry. But this page is not available.</p>
        <CertifiedLogos class="certified-logos my-5 py-5 flex-column"/>

    </PublicLayout>
</template>

<script>
import Logo from "@/components/Logo";
import CertifiedLogos from "@/components/CertifiedLogos";
export default {
    name: "Error404",
    components: {CertifiedLogos, Logo},

}
</script>

<style lang="scss">
#error-404{
    .navbar-brand {
        svg {
            width: 250px;
            height: auto;

            @include media-breakpoint-down('sm') {
                width: 200px;
            }
        }
    }
    .display-1{
        font-size: 13rem;
        color:$primary;
        font-weight: bolder;
        line-height: 1em;

        @include media-breakpoint-down('xl') {
            font-size: 10rem;
        }
        @include media-breakpoint-down('md') {
            font-size: 8rem;
        }
    }
    .display-4{
        @include media-breakpoint-down('md') {
            font-size: $display4-size * 0.7;
        }
        @include media-breakpoint-down('sm') {
            font-size: $display4-size * 0.5;
        }
    }
}
</style>